body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(27, 155, 176);
  border-radius: 10px;
}

.cm-scroller {
  overflow-x: hidden !important;
}

.react-flow__edge.selected .react-flow__edge-path {
  stroke: #555;
}
.react-flow__edge.animated path {
  stroke-dasharray: 5;
  animation: dashdraw 0.5s linear infinite;
}
.react-flow__edge.updating .react-flow__edge-path {
  stroke: #777;
}
.react-flow__edge-path {
  stroke: #b1b1b7;
  stroke-width: 1;
}
.react-flow__edge-text {
  font-size: 10px;
}
.react-flow__edge-textbg {
  fill: white;
}
.react-flow__connection-path {
  stroke: #b1b1b7;
  stroke-width: 1;
}
.react-flow__node {
  cursor: grab;
}
.react-flow__node-default, .react-flow__node-input, .react-flow__node-output, .react-flow__node-group {
  padding: 10px;
  border-radius: 3px;
  width: 150px;
  font-size: 12px;
  color: #222;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  background: #00a0bd !important;
  border-color: #1a192b;
}
.react-flow__node-default.selected, .react-flow__node-input.selected, .react-flow__node-output.selected, .react-flow__node-group.selected {
  box-shadow: 0 0 0 0.5px #1a192b;
}
.react-flow__node-default .react-flow__handle, .react-flow__node-input .react-flow__handle, .react-flow__node-output .react-flow__handle, .react-flow__node-group .react-flow__handle {
  background: #1a192b;
}
.react-flow__node-default.selectable:hover, .react-flow__node-input.selectable:hover, .react-flow__node-output.selectable:hover, .react-flow__node-group.selectable:hover {
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
}
.react-flow__node-default.selectable.selected, .react-flow__node-input.selectable.selected, .react-flow__node-output.selectable.selected, .react-flow__node-group.selectable.selected {
  box-shadow: 0 0 0 0.5px #1a192b;
}
.react-flow__node-group {
  background: rgba(240, 240, 240, 0.25);
  border-color: #1a192b;
}
.react-flow__nodesselection-rect, .react-flow__selection {
  background: rgba(0, 89, 220, 0.08);
  border: 1px dotted rgba(0, 89, 220, 0.8);
}
.react-flow__handle {
  width: 6px;
  height: 6px;
  background: #555;
  border: 1px solid white;
  border-radius: 100%;
}
.react-flow__handle.connectable {
  cursor: crosshair;
}
.react-flow__minimap {
  background-color: #00a0bd !important;
}
.react-flow__controls {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.08);
}
.react-flow__controls-button {
  background: #fefefe;
  border-bottom: 1px solid #eee;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  user-select: none;
  padding: 5px;
}
.react-flow__controls-button svg {
  max-width: 12px;
  max-height: 12px;
}
.react-flow__controls-button:hover {
  background: #f4f4f4;
}
