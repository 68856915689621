/* @import '~antd/dist/antd.css'; */

@import '~rsuite/dist/styles/rsuite-default.css' screen;
/* Supersede dark mode when applicable */
@import '~rsuite/dist/styles/rsuite-dark.css' screen and
  (prefers-color-scheme: dark);

main {
  min-height: 100vh;
}
.site-layout-content {
  padding: 24px;
  min-height: 100vh;
}
#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}
.active-route .rs-nav-item,
.active-route .rs-nav-item-content {
  /* background-color: rgb(27, 155, 176); */
  border-bottom: 1px skyblue solid;
  /* background-color: #d9d9d9; */
}
.note {
  color: rgb(27, 155, 176);
  font-weight: bold;
}

pre {
  overflow: hidden !important;
}
